<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="活动名称"
                    class="width300"
                    prop="title">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="活动区域"
                    prop="list_province">
        <Area v-model="areaAddress"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="活动id"
                    class="width300"
                    prop="act_id">
        <el-input type="text"
                  placeholder="请输入邮政活动Id"
                  v-model="form.act_id"></el-input>
      </el-form-item>
      <el-form-item label="抽奖等级">
        <el-radio-group v-model="form.prize_level_mun"
                        :disabled="$store.state.user.role === 'ADMIN'?false:true">
          <el-radio :label="1"
                    key="1">1个</el-radio>
          <el-radio :label="2"
                    key="2"
                    v-if="form.is_take_part !== 4">多个</el-radio>
          <el-radio :label="3"
                    key="3">接口多星</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <div v-if="form.prize_level_mun === 2">
        <div v-for="(item,index) in form.test3"
             :key="index">
          <div class="header"
               style="display:flex;align-items:center">
            <div class="title mr-15"
                 style="font-weight:bold">抽奖等级{{index+1}}</div>
            <el-button type="text"
                       class="mr-15"
                       v-if="form.test3.length>2"
                       @click="delTestPrizes(index)">删除</el-button>
            <el-button type="text"
                       class="mr-15"
                       v-if="item.show === false"
                       @click="item.show = true">展开</el-button>
            <el-button type="text"
                       class="mr-15"
                       v-if="item.show === true"
                       @click="item.show = false">收起</el-button>
          </div>
          <div v-show="item.show">
            <el-form-item label="抽奖等级名称"
                          class="width300">
              <el-input type="text"
                        placeholder="请输入抽奖等级名称"
                        v-model="item.test_name"></el-input>
            </el-form-item>
            <el-form-item label="抽奖类型">
              <el-radio-group v-model="item.test_type">
                <el-radio :label="1">九宫格</el-radio>
                <el-radio :label="2">直接中奖</el-radio>
                <el-radio :label="3">用户选择</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="奖品"
                          required>
              <el-button type="primary"
                         v-permission="['ADMIN']"
                         @click="addPrize">添加奖品</el-button>
              <el-table :data="form.prize_config"
                        stripe>
                <el-table-column prop="gift_id"
                                 label="礼品id">
                </el-table-column>
                <el-table-column label="奖品名称">
                  <template slot-scope="scope">
                    <span v-if="scope.row.type === 'ENVELOPE'">
                      {{ scope.row.name || scope.row.money+'元幸运包'}}
                    </span>
                    <span v-else>{{ scope.row.name}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="quantity"
                                 label="奖品数量">
                </el-table-column>
                <el-table-column label="奖品类型">
                  <template slot-scope="scope">
                    {{ scope.row.type | prizeType}}
                  </template>
                </el-table-column>
                <el-table-column prop="address"
                                 label="奖品图片">
                  <template slot-scope="scope">
                    <TableImg :url="scope.row.img ? scope.row.img.url :''" />
                  </template>
                </el-table-column>
                <el-table-column label="特殊奖品">
                  <template slot-scope="scope">
                    <span v-if="scope.row.priority === 2">
                      <el-badge is-dot
                                class="mr-15"
                                type="danger"></el-badge>非特殊
                    </span>
                    <span v-else>
                      <el-badge is-dot
                                class="mr-15"
                                type="success"></el-badge>特殊
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button type="text"
                               @click="setPrize(scope)">编辑</el-button>
                    <el-button type="text"
                               v-permission="['ADMIN']"
                               @click="delPrize(scope)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </div>

        </div>
        <el-button type="text"
                   @click="addTest3('form')">新增抽奖等级</el-button>
      </div> -->
      <el-form-item label="抽奖类型"
                    v-if="form.prize_level_mun === 1">
        <el-radio-group v-model="form.draw_type">
          <el-radio :label="1">九宫格</el-radio>
          <el-radio :label="2">直接中奖</el-radio>
          <el-radio :label="3">用户选择</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="奖品"
                    v-if="form.prize_level_mun === 1"
                    required>
        <el-button type="primary"
                   v-permission="['ADMIN']"
                   @click="addPrize">添加奖品</el-button>
        <el-table :data="form.prize_config"
                  stripe>
          <el-table-column prop="gift_id"
                           label="礼品id">
          </el-table-column>
          <el-table-column label="奖品名称">
            <template slot-scope="scope">
              <span v-if="scope.row.type === 'ENVELOPE'">
                {{ scope.row.name || scope.row.money+'元幸运包'}}
              </span>
              <span v-else>{{ scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="total_inventory"
                           label="奖品总库存">
          </el-table-column>
          <el-table-column prop="quantity"
                           label="剩余库存">
          </el-table-column>
          <el-table-column label="奖品类型">
            <template slot-scope="scope">
              {{ scope.row.type | prizeType}}
            </template>
          </el-table-column>
          <el-table-column prop="address"
                           label="奖品图片">
            <template slot-scope="scope">
              <TableImg :url="scope.row.img ? scope.row.img.url :''" />
            </template>
          </el-table-column>
          <el-table-column label="特殊奖品">
            <template slot-scope="scope">
              <span v-if="scope.row.priority === 2">
                <el-badge is-dot
                          class="mr-15"
                          type="danger"></el-badge>非特殊
              </span>
              <span v-else>
                <el-badge is-dot
                          class="mr-15"
                          type="success"></el-badge>特殊
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text"
                         @click="setPrize(scope)">编辑</el-button>
              <el-button type="text"
                         v-permission="['ADMIN']"
                         @click="delPrize(scope)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="活动类型"
                    prop="lucky_type">
        <el-select v-model="form.lucky_type"
                   placeholder="请选择">
          <el-option label="接口活动"
                     value="POSTAL_API">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="验证未通过处理"
                    required>
        <el-radio-group v-model="form.is_take_part"
                        @change="isTakePartChange">
          <el-radio :label="0">不可参与</el-radio>
          <el-radio :label="1">可参与</el-radio>
          <el-radio :label="2">不验证</el-radio>
          <el-radio :label="3">白名单</el-radio>
          <el-radio :label="4">客管接口验证</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="白名单是否发短信"
                    v-if="form.is_take_part === 3"
                    key="is_sms"
                    required>
        <el-radio-group v-model="form.is_sms">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="员工id"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入员工id"
                  v-model="form.marketer_id"></el-input>
      </el-form-item>
      <el-form-item label="是否需要填写网点机构号"
                    required
                    key="is_need_org"
                    v-if="form.is_take_part !== 2">
        <el-radio-group v-model="form.is_need_org">
          <el-radio :label="false">否</el-radio>
          <el-radio :label="true">是</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="封面图片"
                    prop="banner_img_id">
        <ImgUpload :fileList="imgList"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.banner_img_id" />
      </el-form-item>
      <el-form-item label="首页图片"
                    prop="img_id">
        <ImgUpload :fileList="imgList2"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.img_id" />
      </el-form-item>
      <el-form-item label="二维码背景图"
                    prop="qrcode_bg_img_id">
        <ImgUpload :fileList="imgList3"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.qrcode_bg_img_id" />
      </el-form-item>
      <el-form-item label="活动规则"
                    prop="rule">
        <Editor v-model="form.rule" />
      </el-form-item>
      <el-form-item label="起止时间"
                    prop="started_at">
        <el-date-picker v-model="dateTime"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        @change="dateChange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="每用户最高可参与数"
                    class="width300"
                    key="each_num"
                    v-if="form.is_take_part !== 3 "
                    prop="each_num">
        <el-input type="text"
                  placeholder="请输入每用户最高可参与数"
                  v-model.number="form.each_num"></el-input>
      </el-form-item>
      <el-form-item label="每天可参与总次数"
                    class="width300"
                    prop="day_num">
        <el-input type="text"
                  placeholder="每天可参与总次数"
                  v-model.number="form.day_num"></el-input>
      </el-form-item>
      <el-form-item label="初始参与数"
                    class="width300"
                    prop="init_num">
        <el-input type="text"
                  placeholder="请输入初始参与数"
                  v-model.number="form.init_num"></el-input>
      </el-form-item>
      <el-form-item label="优先中奖个数"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入优先中奖个数"
                  v-model.number="form.priority_location_num"></el-input>
      </el-form-item>
      <el-form-item label="判断微商城会员">
        <el-radio-group v-model="form.is_verify_micro_mall">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="员工码是否无限制领取">
        <el-radio-group v-model="form.marketer_no_is_infinite_receive">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="首页是否轮播">
        <el-radio-group v-model="form.is_banner_prize">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="验证不通过提示语"
                    prop="postal_prompt">
        <el-input type="textarea"
                  placeholder="请输入验证不通过提示语"
                  v-model="form.postal_prompt"></el-input>
      </el-form-item>
      <el-form-item label="核销提示语"
                    prop="qrcode_desc">
        <el-input type="textarea"
                  placeholder="请输入核销提示语"
                  v-model="form.qrcode_desc"></el-input>
      </el-form-item>
      <el-form-item label="邮寄实物提示语"
                    prop="take_desc">
        <el-input type="textarea"
                  placeholder="请输入邮寄实物提示语"
                  v-model="form.take_desc"></el-input>
      </el-form-item>
      <el-form-item label="活动举办方"
                    prop="prize_logo_desc">
        <el-input placeholder="请输入活动举办方"
                  v-model="form.prize_logo_desc"></el-input>
      </el-form-item>
      <el-form-item label="如何查询物流"
                    prop="logistic_desc">
        <el-input type="logistic_desc"
                  placeholder="请输入如何查询物流"
                  v-model="form.logistic_desc"></el-input>
      </el-form-item>
      <el-form-item label="查询物流链接"
                    prop="logistic_url">
        <el-input type="textarea"
                  placeholder="请输入查询物流链接"
                  v-model="form.logistic_url"></el-input>
      </el-form-item>
      <el-form-item label="验证窗口提示语"
                    prop="verify_desc">
        <el-input type="textarea"
                  placeholder="验证窗口提示语"
                  v-model="form.verify_desc"></el-input>
      </el-form-item>
      <el-form-item label="图片跳转">
        <ImgUpload :fileList="imgList4"
                   pixel="用于页面配图，建议使用900*383像素"
                   v-model="form.jump_third_party_img_id" />
      </el-form-item>
      <el-form-item label="小程序APPID">
        <el-input type="textarea"
                  placeholder="小程序APPID"
                  v-model="form.jump_third_party_appid"></el-input>
      </el-form-item>
      <el-form-item label="小程序路径">
        <el-input type="textarea"
                  placeholder="小程序路径"
                  v-model="form.jump_third_party_url"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   :loading="loading"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>

    </el-form>
    <SetPrize ref="setPrize"
              @on-success="addPrizeSuccess" />
  </div>
</template>

<script>
import SetPrize from './components/SetPrize'
import { setActivity, getActivity } from '@/api/market'
import TableImg from '@/components/TableImg'
import ImgUpload from '@/components/ImgUpload'
import Editor from '@/components/Editor'
import Area from '@/components/Area'
import { getImgId } from '@/utils'
export default {
  name: 'SetLucky',
  data () {
    return {
      tableData: [],
      imgList: [],
      imgList2: [],
      imgList3: [],
      imgList4: [],
      areaAddress: {},
      dateTime: [],
      loading: false,
      form: {
        jump_third_party_appid: '',
        jump_third_party_url: '',
        jump_third_party_img_id: '',
        type: 'LUCKY',
        is_banner_prize: false,
        title: '',
        is_sms: 0,
        started_at: '',
        is_take_part: 0,
        lucky_type: '',
        ended_at: '',
        rule: '',
        img_id: '',
        banner_img_id: '',
        prize_logo_desc: '',
        qrcode_bg_img_id: '',
        act_id: '',
        draw_type: 1,
        prize_config: [],
        list_province: '',
        list_city: '',
        list_district: '',
        list_net: '',
        day_num: '100000',
        each_num: '',
        total_num: '',
        init_num: 0,
        marketer_id: '',
        delete_prize_ids: [],
        priority_location_num: 0,
        is_need_org: false,
        is_verify_micro_mall: false,
        marketer_no_is_infinite_receive: false,
        postal_prompt: '很抱歉，您不是本次活动的邀约客户，敬请期待下次活动！请联系客户经理了解快速达级方式，参与活动。',
        qrcode_desc: '客户经理将于10个工作日内联系您到网点现场领取奖品实物，如有疑问请联系您所在网点的客户经理。',
        prize_level_mun: 1,
        take_desc: '领奖用户请提交如下信息，仅在广州地区派送',
        logistic_desc: '复制物流号，到“广州邮政公众号-客户服务-邮件查询“查询物流动态；或直接点击下方查询物流按钮。',
        logistic_url: 'https://webchat.183gz.com.cn/query/?code=013Xf61w3b9pXV206z1w3eDWkN1Xf61s&state=stamp_oauth#/mail',
        verify_desc: '请先验证您是否活动特邀客户，所填信息需与银行预留信息一致'
      },
      rules: {
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        list_province: [
          { required: true, message: '至少选择省份', trigger: 'change' }
        ],
        act_id: [
          { required: true, message: '请输入邮政活动id', trigger: 'blur' }
        ],
        lucky_type: [
          { required: true, message: '请选择活动类型', trigger: 'change' }
        ],
        img_id: [
          { required: true, message: '请上传首页图片', trigger: 'change' }
        ],
        banner_img_id: [
          { required: true, message: '请上传封面图片', trigger: 'change' }
        ],
        prize_logo_desc: [
          { required: true, message: '请输入活动举办方文字', trigger: 'blur' }
        ],
        qrcode_bg_img_id: [
          { required: true, message: '请上传二维码图片', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请输入活动规则', trigger: 'change' }
        ],
        started_at: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ],
        each_num: [
          { required: true, message: '请输入每用户最高可参与数', trigger: 'blur' }
        ],
        day_num: [
          { required: true, message: '每天可参与总次数', trigger: 'blur' }
        ],
        init_num: [
          { required: true, message: '请输入初始参与数', trigger: 'blur' }
        ],
        postal_prompt: [
          { required: true, message: '请输入提示语', trigger: 'blur' }
        ],
        qrcode_desc: [
          { required: true, message: '请输入核销提示语', trigger: 'blur' }
        ],
        take_desc: [
          { required: true, message: '请输入邮寄实物提示语', trigger: 'blur' }
        ],
        logistic_desc: [
          { required: true, message: '请输入如何查询物流', trigger: 'blur' }
        ],
        verify_desc: [
          { required: true, message: '请输入验证窗口提示语', trigger: 'blur' }
        ]

      },
      prize_config: [
        {
          gift_id: '',
          name: '',
          type: '',
          img_id: '',
          coupon_id: '',
          quantity: '',
          imgList: [],
          money: '',
          priority: 2
        }
      ],
      prizeTypes: [
        {
          value: 'ENVELOPE',
          label: '红包'
        },
        {
          value: 'COUPON',
          label: '代金券'
        },
        {
          value: 'KIND',
          label: '邮寄实物'
        },
        {
          value: 'VERIFY',
          label: '网点核销'
        },
        {
          value: 'RECHARGE',
          label: '直充会员'
        },
        {
          value: 'OPEN_AREA_VERIFY',
          label: '自选网点核销'
        }
      ]
    }
  },
  components: {
    Area,
    ImgUpload,
    Editor,
    SetPrize,
    TableImg
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        // do something
        if (newVal.is_take_part === 3) {
          this.form.each_num = 1
        }
      },
      deep: true
    }
  },
  filters: {
    prizeType (val) {
      if (val) {
        switch (val) {
          case 'ENVELOPE':
            return '红包'
          case 'COUPON':
            return '代金券'
          case 'KIND':
            return '邮寄实物'
          case 'VERIFY':
            return '网点核销'
          case 'EXPENSES':
            return '话费充值'
          case 'CARD_KEY':
            return '卡密'
          case 'RECHARGE':
            return '直充会员'
          case 'OPEN_AREA_VERIFY':
            return '自选网点核销'
          default:
            return ''
        }
      } else {
        return ''
      }
    }
  },
  computed: {

  },
  methods: {
    isTakePartChange (e) {
      if (e === 4) {
        this.form.prize_level_mun = 1
      }
    },
    delTestPrizes (index) {
      this.form.test3.splice(index, 1)
    },
    addTest3 () {
      this.form.test3.push({
        test_name: '',
        test_type: 1,
        test_prizes: [],
        show: false
      })
    },
    addPrizeSuccess (res, index) {
      if (index < 0) {
        this.form.prize_config.push(res)
      } else {
        this.$set(this.form.prize_config, index, res)
        this.form.prize_config[index] = res
      }
    },
    addPrize () {
      if (this.form.prize_config.length > 7) {
        this.$message.error('最多存在8个')
      } else {
        this.$refs.setPrize.showForm()
      }
    },
    setPrize (scpoe) {
      this.$refs.setPrize.showForm(scpoe)
    },
    delPrize (scope) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        this.form.prize_config.splice(scope.$index, 1)
        this.form.delete_prize_ids.push(scope.row.id)
      })
    },
    dateChange (e) {
      if (e) {
        this.form.started_at = e[0]
        this.form.ended_at = e[1]
      } else {
        this.form.started_at = ''
        this.form.ended_at = ''
      }
    },
    areaChange (address) {
      const { form } = this
      form.list_province = address.province
      form.list_city = address.city
      form.list_district = address.district
      form.list_net = address.net
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.form.prize_level_mun === 1) {
            if (this.form.prize_config.length === 0) {
              this.$message.error('请添加奖品')
              return
            }
          }
          const formData = JSON.parse(
            JSON.stringify(this.form)
          )
          formData.prize_config.map(item => {
            item.money = item.money * 100
            item.expenses = item.expenses * 100
          })
          this.loading = true
          const res = await setActivity(formData)
          this.loading = false
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('lucky')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getActivity (id) {
      const res = await getActivity({
        activity_id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity

        activity.activity_id = id
        activity.img_id = getImgId(activity.img_data)
        activity.img_data && this.$set(this.imgList2, 0, activity.img_data)
        activity.banner_img_id = getImgId(activity.banner_img_data)
        activity.banner_img_data && this.$set(this.imgList, 0, activity.banner_img_data)
        activity.qrcode_bg_img_id = getImgId(activity.qrcode_bg_img_data)
        activity.qrcode_bg_img_data && this.$set(this.imgList3, 0, activity.qrcode_bg_img_data)
        activity.jump_third_party_img_id = getImgId(activity.jump_third_party_img_data)
        activity.jump_third_party_img_data && this.$set(this.imgList4, 0, activity.jump_third_party_img_data)
        this.areaAddress = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }
        this.dateTime = [activity.started_at, activity.ended_at]
        const prizeConfig = JSON.parse(
          JSON.stringify(activity.prize_config)
        )
        this.prize_config = []
        prizeConfig.map((item, index) => {
          item.money = item.money / 100
          item.expenses = item.expenses / 100
          item.img_id = item.img.id
          item.imgList = [item.img]
        })
        activity.prize_config = prizeConfig
        this.form = activity
        this.form.delete_prize_ids = []
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getActivity(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
