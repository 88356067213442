<template>
  <div class="">
    <el-dialog title="设置卡密"
               :close-on-click-modal='false'
               :visible.sync="dialogVisible"
               width="60%">
      <el-form :model="form"
               :disabled="disabled"
               :rules="rules"
               label-position="top"
               ref="form">
        <el-form-item label="卡密id"
                      prop="card_id">
          <el-select v-model="form.card_id"
                     filterable
                     class="width100"
                     remote
                     @change="cardChange"
                     placeholder="请输入卡密id"
                     :remote-method="fetchCard">
            <el-option v-for="item in cardList"
                       :key="item.id"
                       :label="item.id"
                       :value="item.id">
              {{item.id+'('+item.name+')'}}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡密名称"
                      prop="name">
          <el-input type="text"
                    class="mr-15"
                    disabled
                    placeholder="设置卡密名称"
                    v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="数量"
                      prop="each_num">
          <el-input type="text"
                    placeholder="请输入每人可领数量"
                    v-model="form.each_num"></el-input>
        </el-form-item>
        <el-form-item class="tr">
          <el-button type="primary"
                     @click="submitForm('form')">立即{{index>=0?'修改':'创建'}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import { getCardTrickList } from '@/api/market'
export default {
  name: '',
  data () {
    return {
      cardList: [],
      dialogVisible: false,
      form: {
        name: '',
        card_id: '',
        each_num: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入奖品名称', trigger: 'blur' }
        ],
        each_num: [
          { required: true, message: '请输入每人可领数量', trigger: 'blur' }
        ],
        card_id: [
          {
            required: true, message: '请输入卡密id', trigger: 'blur'
          }
        ]
      },
      index: -1,
      options: []
    }
  },
  components: {
  },
  computed: {
    disabled () {
      if (this.$store.state.user.role !== 'ADMIN') {
        if (this.form.id) {
          if (this.form.type === 'VERIFY' || this.form.type === 'KIND') {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  methods: {
    cardChange (e) {
      const res = this.cardList.find((item) => {
        return item.id === e
      })
      this.form.name = res.name
    },
    async fetchCard (query) {
      if (query !== '') {
        const res = await getCardTrickList({
          id: query
        })
        if (res.meta.code === 0) {
          this.cardList = res.data.cards
        }
      } else {
        this.cardList = []
      }
    },
    showForm (scpoe) {
      this.dialogVisible = true
      if (scpoe) {
        this.index = scpoe.$index
        const formData = Object.assign({}, scpoe.row)
        this.form = formData
      } else {
        this.form = {
          name: '',
          card_id: '',
          each_num: ''
        }
        this.index = -1
        this.cardList = []
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },

    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$emit('on-success', this.form, this.index)
          this.dialogVisible = false
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
